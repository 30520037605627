import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'ngx-etudiant',
  templateUrl: './etudiant.component.html',
  styleUrls: ['./etudiant.component.scss']
})
export class EtudiantComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
