
export const User_MENU_ITEMS = [
  {
    title: 'Home',
    icon: 'home-outline',
    link: '/pages/utilisateur/home',
  },
  {
    title: 'Pre-inscription',
    icon: 'edit-outline',
    link: '/pages/utilisateur/pre-inscription',
  }
];
